.main-title{text-align: center;}

@media (max-width: 1200px) {
    
}

@media (max-width: 768px) {
    
}

/* .downloadbutton{position: absolute; top:80px; right:-30px;padding: 2px 5px; background: #D0D3D4;color: #1F618D;text-align: center;}
.downloadbutton p{font-size: 10px;margin-bottom: 0px;}
.downloadbutton:hover{cursor: pointer; background: #1F618D;color: #FFF;}

.downloadbutton3{position: absolute; top:170px; right:-30px;padding: 2px 5px; background: #D0D3D4;color: #1F618D;text-align: center;}
.downloadbutton3 p{font-size: 10px;margin-bottom: 0px;}
.downloadbutton3:hover{cursor: pointer; background: #1F618D;color: #FFF;}


.downloadbutton2{position: absolute; top:125px; right:-30px;padding: 2px 5px; background: #D0D3D4;color: #1F618D;text-align: center;}
.downloadbutton2 p{font-size: 10px;margin-bottom: 0px;}
.downloadbutton2:hover{cursor: pointer; background: #1F618D;color: #FFF;}

.downloadbutton4{position: absolute; top:215px; right:-30px;padding: 2px 5px; background: #D0D3D4;color: #1F618D;text-align: center;}
.downloadbutton4 p{font-size: 10px;margin-bottom: 0px;}
.downloadbutton4:hover{cursor: pointer; background: #1F618D;color: #FFF;} */

.chartdestxt{text-align: center; padding:20px 20px 0px 20px;}

.download-links-list{position: absolute;list-style: none;top: 45px;right: -80px;padding: 10px;border-radius: 6px;background: #fff;box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);}
.download-links-list li{padding: 6px;text-align: center;font-size: 12px;font-weight: 600;border-radius: 4px;transition: all .3s;-webkit-transition: all .3s;}
.download-links-list li:hover{background: #725cd3;cursor: pointer;font-weight: 500;}
.download-links-list li svg{color: #725cd3;}
.download-links-list li p{margin-bottom: 0px;color: #2D568C;}

.downloadopt{width: 28px;height: 28px;background: #ccc;background: url("../../../assets/img/export-list.png");background-size: auto 100%;}
.downloadopt.pdf + p{color: #c70f0f;}
.downloadopt.png{background-position-x: -28px;}
.downloadopt.png + p{color: #e18113;}
.downloadopt.excel{background-position-x: -56px;}
.downloadopt.excel + p{color: #357906;}
.downloadopt.ppt{background-position-x: -84px;}
.downloadopt.ppt + p{color: #ec6203;}

.download-links-list li:hover p{color: #fff;}
.download-links-list li:hover .downloadopt{filter: brightness(100);}
.download-links-list li .downloadbutton2{position: relative;}
.download-table-xls-button{position: absolute;width: 100%;height: 100%;background: transparent;top: 0px;left: 0px;border: none;}

.main-filter-content{position: relative;}
.main-filter-content h5{font-weight: 600;color: #2D568C;margin-left: 10px;margin-top: 5px;padding-right: 70px;}
.main-filter-content .table-toggle-list .btn{border-radius: 25%;background: #afa0f3;border-color: #afa0f3;}
.main-filter-content .table-toggle-list .btn-primary{background: #7052fb;border-color: #7052fb;}

/* .apexcharts-text tspan, .apexcharts-yaxis-title text, .apexcharts-xaxis-title text, .apexcharts-legend-text{font-family: 'Poppins', sans-serif !important;} */
.apexcharts-text tspan{font-weight: 600;font-size: 13px;}
.apexcharts-yaxis-title text, .apexcharts-xaxis-title text{font-size: 14px;font-weight: 600;fill: #17202A;}
.apexcharts-legend-text{font-size: 13px !important;font-weight: 600 !important;}
.main-pie-chart .apexcharts-legend{height: fit-content;padding: 5px !important;border-radius: 8px;}/*box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);border:1px solid #ddd;*/
.main-pie-chart .apexcharts-legend .apexcharts-legend-series{margin: 1px !important;}

.ppt .apexcharts-text tspan{font-weight: 700 !important;font-size: 13px !important; fill:#17202A;}
.ppt .apexcharts-yaxis-title text, .ppt .apexcharts-xaxis-title text{font-size:14px; fill:#17202A;}
.ppt .apexcharts-legend-text{font-size: 14px !important;font-weight: 700;}

.pdf .apexcharts-text tspan{font-weight: 700;font-size: 13px !important;}
.pdf .apexcharts-yaxis-title text, .pdf .apexcharts-xaxis-title text{fill:#34495E;}

.line-zoomicon{width: 25px;opacity: 0.6;margin-top: 2px;}
.line-zoomicon:hover{opacity: 1;}

.apexcharts-toolbar{z-index: 1;}
.arrow_box {position: relative;background: #fff;border: 2px solid #fff;padding: 5px 8px;font-size: 12px;color: #17202A;font-weight: 600;}

.chartview-legend{position: absolute;right: 10px;top: 70px;padding: 10px;border: 1px solid #ccc;padding-left: 25px;border-radius: 6px;}
.chartview-legend ul{list-style: none;padding-left: 0px;margin-bottom: 0px;}
.chartview-legend ul li{font-size: 12px;font-weight: 600;margin-bottom: 1px;padding: 3px 0px;color: #34495E;width: 220px;position: relative;}
/* .chartview-legend ul li:hover{opacity: .8;cursor: pointer;} */
.chartview-legend ul li div{width: 12px;height: 12px;background: #ccc;border-radius: 6px;position: absolute;margin-left: -16px;margin-top: 4px;}
.chartview-legend ul li span{position: absolute;right: 0px;font-weight: 700;}

.method-redirect-btn{margin-top:5px;color: #725cd3;}
.method-redirect-btn:hover{cursor: pointer; color: darkblue;}
.method-redirect-img:hover{cursor: pointer;}

.export-loading-div{
     position: fixed;
    top: 0;
    z-index: 10000000;
    left: 0;
    background:black;
    width: 100vw;
    height: 100%;
    opacity: 0.8;
    color: #FFF;
    
    -webkit-animation: fadein 0.5s;
        -moz-animation: fadein 0.5s; 
        -ms-animation: fadein 0.5s; 
         -o-animation: fadein 0.5s; 
            animation: fadein 0.5s; 
}
/* .export-loading-modal .modal-content{background: transparent;color: #fff;text-align: center;display: unset;border: none;} */
.export-loading-div span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
     
}

.export-loading-div span .loadingicon{
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}


@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}



@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}


/* @media (max-width: 768px) {
    #mainchartdiv{width:700px; overflow-x: auto;}
} */

/* .ppt-confrim-modal .modal-content{background: linear-gradient(130deg, #ffffff, #ffffff, #d6e3f5);backdrop-filter: blur(3px);} */
.mainchart-overlay{width:100%;}

@media (max-width: 995px) {
    .mainchart-overlay{width:90%;overflow-x:auto;}
    .chart-logo-main-div{margin-top: 30px;}
}


@media (max-width: 850px) {
    .mainchart-overlay{width:90%;overflow-x:auto;}
    .download-links-list {top: 165px;right: -35px;}
    export-loading-div span{font-size: 12px;}
    .mainchart-overlay{max-height:unset !important;}
    /*  .export-loading-modal .modal-dialog{width: 100%;height: 100%;}
   .export-loading-modal.show + .modal-backdrop{height: 100%;width: 100vw;} */
}

@media (max-width: 768px) {
    .mainchart-overlay{width:90%;overflow-x:auto;}
    .download-links-list {top: 165px;right: -35px;}
    export-loading-div span{font-size: 12px;}
    #exceltable1{margin-top:52px !important;}
    .chart-logo-img{width:130px}
    /*  .export-loading-modal .modal-dialog{width: 100%;height: 100%;}
   .export-loading-modal.show + .modal-backdrop{height: 100%;width: 100vw;} */
}

@media (max-width: 365px) {
    .main-table-view-div{width:100%; overflow-x:auto ;}
    #exceltable1{margin-top:10px !important;}
    .chart-logo-img{width:100px}
}