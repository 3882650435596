
/* .main-inner-content{background: #ECF0F1;} */
.middle-content-header{margin-bottom: 10px !important;font-size: 23px;}

.containts-div{padding:15px;}
.containts-inner{background-color: #FFF; padding:15px;border-radius: 10px;box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);}

.changechart-modal .modal-dialog{max-width: 65%;}
.changechart-modal .modal-content{background: linear-gradient(130deg, #ffffff, #ffffff, #d6e3f5);backdrop-filter: blur(3px);height: 350px;overflow: hidden;overflow-y: auto;}
.changechart-modal h4{text-transform: uppercase;font-weight: 700;margin-bottom: 15px;color: #2D568C;}
.changechart-modal .mega-subitem-main .col{padding: 15px;background: rgba(255,255,255,0.1);border: 1px solid #ddd;font-size: 15px;font-weight: 700;border-radius: 6px;transition: all .3s;-webkit-transition: all .3s;position: relative;margin-bottom: 10px;color: #2D568C;box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);background: #fff;}
.changechart-modal .mega-subitem-main .col:hover{background: #fdb01a;cursor: pointer;} /* color: #fff; */
.changechart-modal .mega-subitem-main .col svg{margin-right: 10px;color: #555;}
/* .changechart-modal .mega-subitem-main .col:hover svg{color: #fff;}
.changechart-modal .mega-subitem-main .col:hover img{filter: invert();} */
.changechart-modal .mega-subitem-main .col .chev-right svg{margin-right: 0px;margin-left: 10px;}
.changechart-modal .mega-subitem-main .col .chev-right{position: absolute;right: 5px;opacity: 0;transition: all .3s;-webkit-transition: all .3s;}
.changechart-modal .mega-subitem-main .col:hover .chev-right{opacity: 1;}
.changechart-modal .homelink-btn{position: absolute;right: 10px;border-radius: 25px;padding: 4px 15px;top: 15px;font-size: 14px;font-weight: 600;border-color: #0F3057;color: #0F3057;;}
.changechart-modal .homelink-btn:hover{color: #fff;background: #0F3057;}
.changechartview-main{padding: 0px 5px;}
.changechartview-main .col-4{padding: 0px 8px;margin-bottom: 10px;position: relative;}
.changechartview-main .col-4 .thumb-link{text-align: center;background: #fff;border-radius: 6px;box-shadow: 0px 1px 5px rgb(0 0 0 / 20%);padding: 6px 10px;transition: all .3s;-webkit-transition: all .3s;}
.changechartview-main .col-4:hover .thumb-link, .changechartview-main .col-4 .thumb-link:hover{cursor: pointer;opacity: .6;background: #fdc34e;}
.changechartview-main .col-4.active .thumb-link{background: #fdb01a;}
.changechartview-main .col-4 .thumb-link img{height: 23px;}
.changechartview-main .col-4 .changechartview-inner-content{display: none;position: absolute;z-index: 999;width: 350px;margin-top: 1px;padding: 15px 10px;background: #fff;border-radius: 6px;box-shadow: 0px 10px 35px rgb(0 0 0 / 30%);min-height: 190px;}
.changechartview-main .col-4:hover .changechartview-inner-content{display: block;}
.changechartview-main .col-4 .changechartview-subitem-main{padding: 15px;padding-left: 5px;border-radius: 4px;box-shadow: 0px 2px 15px rgb(0 0 0 / 20%);position: relative;transition: all .3s;-webkit-transition: all .3s;font-size: 14px;font-weight: 500;margin-bottom: 5px;}
.changechartview-main .col-4 .changechartview-subitem-main svg, .changechartview-main .col-4 .changechartview-subitem-main img{margin-right: 10px;margin-left: 8px;}
.changechartview-main .col-4 .changechartview-subitem-main:hover, .changechartview-main .col-4 .changechartview-subitem-main.active{background: #fdb01a;cursor: pointer;}
.changechartview-main .col-4 .changechartview-subitem-main .chev-right{position: absolute;right: -5px;top: 15px;}

@media (max-width: 1200px) {
    
}

@media (max-width: 768px) {
    .containts-div.col-lg-9{width: 96%;}
    .changechart-modal .modal-dialog{max-width: 100% !important;}
    .changechart-modal .modal-content{height: auto;}
    .table-toggle-list{margin-top: 25px !important;}
}

@media (max-width: 576px) {
    .mchart-view{margin-top: 45px;}
    .changechartview-main .col-4{position: unset;}
    .changechartview-main .col-4 .changechartview-inner-content{left: 10px;width: 95%;margin-top: 5px;}
}