.custom-legend{padding: 0px 5px 5px 50px;font-size: 13px;}
.custom-legend .dot{width: 10px;
    height: 10px;
    border-radius: 6px;
    position: absolute;
    margin-left: -14px;
    margin-top: 4px;
}

.custom-legend .main-item{margin:5px 10px 5px 10px;display: inline-block;}



