

.slider {position: relative;width: 200px;}
.slider__track,.slider__range,.slider__left-value,.slider__right-value {position: absolute;}
.slider__track,.slider__range {border-radius: 3px;height: 5px;}
.slider__track {background-color: #ced4da;width: 100%;z-index: 1;}
.slider__range {background-color: #9fe5e1;z-index: 2;}
.slider__left-value,.slider__right-value {color: #dee2e6;font-size: 12px;margin-top: 20px;}
.slider__left-value {left: 6px;}
.slider__right-value {right: -4px;}
.thumb,.thumb::-webkit-slider-thumb {-webkit-appearance: none;-webkit-tap-highlight-color: transparent;}
.thumb {pointer-events: none;position: absolute;height: 0;width: 200px;outline: none;}
.thumb--left {z-index: 3;}
.thumb--right {z-index: 4;}
.thumb::-webkit-slider-thumb {background-color: #f1f5f7;border: none;border-radius: 50%;box-shadow: 0 0 1px 1px #ced4da;cursor: pointer;height: 18px;width: 18px;margin-top: 4px;pointer-events: all;position: relative;}
.thumb::-moz-range-thumb {background-color: #f1f5f7;border: none;border-radius: 50%;box-shadow: 0 0 1px 1px #ced4da;cursor: pointer;height: 18px;width: 18px;margin-top: 4px;pointer-events: all;position: relative;}

.single-filter-content{margin-bottom: 15px;}
.multiCanceSiteItem{padding: 6px 10px; background: #725cd3;margin:2px; font-size: 13px;border-radius: 20px;display: inline-block;font-weight: 600;padding-right: 12px;color:#fff;}
.multiCanceSiteItem svg{margin-right: 5px;stroke: #fff;}
.multiCanceSiteItem svg:hover{cursor: pointer;}

.single-filter-content .filter-label{margin-bottom: 8px;font-size: 14px;font-weight: 700;color: #2D568C;}
.single-filter-content .form-check-main{padding: 0px;background:#fff;border-radius: 20px;}
.single-filter-content .form-check label{font-size: 13px;font-weight: 600;width: 100%;position: absolute;left: 0px;top: 0px;padding-top: 6px;height: 35px;text-align: center;}
.single-filter-content .form-check label:hover, .single-filter-content .form-check input:hover{cursor: pointer;font-weight: 600;}
.single-filter-content .form-control{border-radius: 6px;box-shadow: 0px 1px 2px rgba(0,0,0,.2);padding: 6px 10px;font-weight: 600;}
.single-filter-content .btn-success{background: #797A7E;color: #fff;border-color: #797A7E;border-radius: 6px;padding: 6px;font-size: 14px;}
.single-filter-content .form-check{padding: 5px 0px;padding-left: 30px;background: #fff;border-radius: 20px;position: relative;height: 32px;overflow: hidden;margin-bottom: 0px;border: 1px solid #afa0f3;color: #2D568C;}
.single-filter-content .form-check input[type="radio"]{z-index: 1;position: relative;display: none;}
.single-filter-content .form-check input[type="radio"]:checked + label{background: #725cd3;color: #fff;}
.single-filter-content .form-check input[type="radio"]:checked + label div{filter: invert() brightness(1.5);}
.single-filter-content .filterselect__control{min-height: 20px;height: 38px;}
.single-filter-content .filterselect__single-value{font-size: 14px;font-weight: 600;}
.single-filter-content .filterselect__placeholder{font-size: 14px;}
.single-filter-content .filterselect__menu{z-index: 9;}
.single-filter-content .form-check.active{border:none;}

.single-filter-content .filter-gender{width: 20px;height: 20px;position: absolute;left: 5px;background: url("../../../assets/img/filters_gender.png");background-size: cover;}
.single-filter-content .filter-gender.male{background-position: -45px;}
.single-filter-content .filter-gender.female{background-position: -21px;}

.single-filter-content .filter-results{width: 20px;height: 20px;position: absolute;left: 5px;background: url("../../../assets/img/filters_results.png");background-size: cover;}
.single-filter-content .filter-results.all{background-position: -21px;}

.single-filter-content .filter-category{width: 20px;height: 20px;position: absolute;left: 5px;background: url("../../../assets/img/filters_category.png");background-size: cover;background-position: -21px;}
.single-filter-content .filter-category.gender{background-position: 0px;}

.cancersiye-changetxt{float: right;font-size: 13px; font-weight: 500;margin-top: 2px;padding-right: 0px;}
.cancersiye-changetxt:hover{font-weight: 600; cursor: pointer;text-decoration: underline;}

.switch input {cursor: pointer;height: 100%;opacity: 0;position: absolute;width: 100%;z-index: 100;}
.switch {height: 20px;margin: 0px auto;position: relative;width: 33px;margin-top: -20px;margin-left: 65px;}
.switch label {background: #DDDDDD;color: #DDDDDD;border-radius: 10px;display: block;height: 100%;position: relative;transition: all .15s ease;width: 100%;}
.switch label div {background: #fff;border-radius: 10px;display: block;height: 14px;left: 3px;position: absolute;top: 3px;transition: all .3s ease;width: 15px;z-index: 3;}
.switch .fontawesome-ok {font-size: 28px;left: 20px;position: relative;top: 13px;z-index: 2;}
.switch .fontawesome-remove {font-size: 29px;left: 51px;position: relative;top: 12px;z-index: 2;}
.switch input:checked~label {background: #725cd3;color: #725cd3;}
.switch input:checked~label div {left: 15px;}


@media (max-width: 1200px) {
    
}

@media (max-width: 768px) {
    
}

@media (max-width: 576px) {
    .containts-div.col-lg-9 {width: 100%;}
}