
.fact-download-link{border-radius: 15px;position: relative;z-index: 3;padding: 5px 15px;font-weight: 600;}

.lds-dual-ring {display: block;width: 80px;height: 80px;margin: 0 auto;position: absolute;margin-left: 30%;margin-top: 15%;}
.lds-dual-ring:after {content: " ";display: block;width: 64px;height: 64px;margin: 8px;border-radius: 50%;border: 6px solid #725cd3;border-color: #725cd3 transparent #725cd3 transparent;animation: lds-dual-ring 1.2s linear infinite;}

.factview-download-link{position:absolute; height:40px; margin-top:-40px; right:0px;}

@keyframes lds-dual-ring {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}}
  

@media (max-width: 1200px) {
    
}

@media (max-width: 768px) {
    .factview-download-link{margin-top: -5px;position: relative;}
    .fact-download-link{margin-top: 3px;}
}
