.method-card {border: none;background: #fff;box-shadow: 0px 0px 20px rgba(0,0,0,0.15);padding: 10px 15px;margin-bottom: 20px;}
.method-card .main-title{color:#2D568C;margin-bottom: 20px;font-weight: 600;}
.method-card .main-title div{display: inline-block;padding: 5px 20px; background: #ad9bff33; border-radius: 20px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.method-card .main-sub-title{color: #2D568C;margin-bottom: 20px;font-weight: 600;}
.method-card .main-sub-title div{display: inline-block;padding: 5px 20px; background: #ad9bff33; border-radius: 20px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.method-card p { text-align:justify;font-size: 16px;font-weight: 500;}
.method-card table{margin-bottom: 5px; font-size: 14px;}
.method-card table ol{margin-bottom: 0px;}
.method-card table ol li{margin-bottom: 8px;}
.method-card table thead tr th{color: #FFF;background-color:#725cd3;border: 1px solid#725cd3;}
.method-card table tbody tr td{font-weight: 500;}
.method-card table tbody tr td .tdheader{font-weight: 600; color: #2D568C; font-size: 16px;margin-bottom: 8px;}

.processimgsiv{text-align: center; margin-top: 20px;}
.processimgsiv img{width: 75%;}
.processimgsiv h6{margin-bottom: 10px;text-align: left;}

.method-card .summary-main-col{padding: 10px;}
.method-card .summary-panel{min-height: 540px;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;background:#FBFCFC;border-radius: 5px;}
.method-card .summarypanel-body-item{padding: 10px;font-size: 14px;margin-bottom: 5px;background: #FFF; border-radius: 5px;background-color:#FFF ;height: 120px;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;}
method-card .summarypanel-body-item ul{margin-bottom: 0px;}
.method-card .summarypanel-body-item.list{height: 260px; background-color: #FFF;}
.method-card .summarypanel-body-item h6{text-align: center;font-size: 13px;margin-bottom: 5px;}
.method-card .summarypanel-body-item h5{text-align: center;font-size: 15px; color:#34495E; font-weight: 500;}
.method-card .summarypanel-body-item table tbody tr td{padding: 5px;}
.method-card .summarypanel-body-item table tbody tr td .subdiv{border-radius: 5px;color:#34495E;  padding: 10px;text-align:center;box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;}
.method-card .main-col-summary{padding:10px;}
/* .method-card .summarypanel-body-item.third table tbody tr td .subdiv{padding-top: 15%; height: 150px;} */

.method-card .summarypanel-body-item.topic1 h6{color: #3498DB;}
.method-card .summarypanel-body-item.topic2 h6{color: #D68910;}
.method-card .summarypanel-body-item.topic3 h6{color: #229954;}
.method-card .summarypanel-body-item.topic4 h6{color: #CB4335;}

.method-card .summarypanel-header{
    text-align: center; 
    padding: 10px; 
    font-size: 18px; 
    font-weight: 600;
    color:#FFF;
    background: #725cd3;;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
    /* width:95%;
    height:50px;
    background: chartreuse;
    -webkit-transform: perspective(300px) rotateX(-40deg);
    -o-transform: perspective(300px) rotateX(-30deg);
    -moz-transform: perspective(300px) rotateX(-30deg);
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%; */
   
}






@media (max-width: 1200px) {
    .processimgsiv img{width: 95%;}
    
}

@media (max-width: 992px) {
    .processimgsiv img{width: 95%;}
    .method-card .summary-panel{min-height: 100px}
    .method-card .summarypanel-body-item.list{height: auto;}
    .method-card .summarypanel-body-item.third table tbody tr td .subdiv{padding-top: 10px; height: auto;}
}

@media (max-width: 767px) {
    .processimgsiv img{width: 95%;}
}

@media (max-width: 576px) {
    .processimgsiv img{width: 95%;}
    .method-card p { font-size: 15px;}
    .method-card table tbody tr td{font-size: 15px;}
    .method-card table tbody tr td .tdheader{font-size: 13px;}
}

@media (max-width: 435px) {
    .method-card .main-title{font-size: 18px;}
    .method-card .main-sub-title{font-size: 17px;}
}

@media (max-width: 365px) {
    .processimgsiv img{width: 95%;}
    .method-card p { font-size: 13px;}
    .method-card table tbody tr td{font-size: 14px;}
    .method-card table tbody tr td .tdheader{font-size: 13px;}
}