@import url(./assets/css/poppins.css?family=Poppins:300,400,500,600,700,800);

body{font-family: 'Poppins', sans-serif;min-height: 100%;background: #fff;}
body.d-mode{background: #22272f;}

.col-centered{margin:0 auto !important;float:none;}
.centered{position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.div-con{padding: 0px;margin: 0px;}
.float-right{float: right;} .float-left{float: left;} .float-none{float: none;}
::-webkit-scrollbar {-webkit-appearance: none;width: 7px;height: 7px;}
::-webkit-scrollbar-track { background: rgba(0, 0, 0, .1); }
::-webkit-scrollbar-thumb {border-radius: 4px;background-color: #725cd3;box-shadow: 0 0 1px rgba(255, 255, 255, .5);}
::-webkit-scrollba:hover{cursor: pointer;}

body.d-mode ::-webkit-scrollbar-thumb{ background: #2CC990; }
.text-right{text-align: right;}
.text-left{text-align: left;}
a{text-decoration: none;}

.alert{position: fixed;z-index:1999;left: 50%;transform: translate(-50%,25px);border:none;font-weight: 600;font-size: 14px;color:#0F3057;padding: 10px 30px;}
.alert span{margin-right: 15px;}
.alert .close{opacity: 0.5;text-shadow: none;cursor: pointer;margin-bottom: 0px;font-size: 1.3rem;position: absolute;margin-top: -5px;right: 15px;}
.alert .close:hover{opacity: 1;}
.alert-danger{background: #F1654C;}
.alert-success{background: #2CC990;}
.alert-info{background: #44BBFF;}
.alert-warning{background: #FFC153;}

/* .wrapper-container{display: flex;flex-direction: column;min-height: 100vh;} */
.navbar-main .navbar{background-color: transparent !important;position: absolute;z-index: 9;width: 100%;position: absolute;}
.navbar-main .navbar-brand{padding: 15px;padding-left: 75px;margin-left: -40px;margin-right: 0px;}
.navbar-main.subpage-nav .navbar{background-color: #fff !important;height: 70px;box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);}
.navbar-main .navbar .navbar-logo{margin-top: 15px;height: 45px;}
.navbar-main.subpage-nav .navbar-logo{margin-top: 0px;}
.navbar-main .navbar .navbar-formlist{float: right;text-align: right;right: 40px;top: 1.2rem;position: absolute;}
.navbar-main.subpage-nav .navbar-formlist{top: 0.1rem;}
.navbar-main .navbar .navbar-formlist .btn-success{background: transparent;font-size: 12px;font-weight: 600;padding: 7px 20px;border: 1px solid #fff;border-radius: 18px;width: 130px;}
body.l-mode .navbar-main .navbar .navbar-formlist .btn-success{border-color: #0F3057;color: #0F3057;}
.navbar-main .navbar .navbar-formlist .dropdown-menu{padding: 10px;margin-left: -36px !important;margin-top: 5px !important;border-radius: 15px;}
.navbar-main .navbar .navbar-formlist .dropdown-menu option{font-size: 14px;padding: 5px 8px;font-weight: 600;border-radius: 4px;}
.navbar-main .navbar .navbar-formlist .dropdown-menu option:hover{cursor: pointer;background: #B8B5FF;}
.navbar-main.subpage-nav .navbar-formlist .btn-success{border-color: #42729B;color: #2D568C;margin-top: 0px;}
.navbar-main .navbar .navbar-linklist{margin-bottom: 0px;}
.navbar-main .navbar .navbar-linklist li div{padding: 10px 14px;border-radius: 25px;height: 38px;font-weight: 600;color: #2D568C;font-size: 14px;}
.navbar-main .navbar .navbar-linklist li:hover div{background: #e0defd;cursor: pointer;}
.navbar-main .navbar .navbar-linklist li.active div{background: #725cd3 !important;color: #fff;}
.navbar-main .navbar .navbar-search{position: relative;}
.navbar-main .navbar .navbar-search svg{position: absolute;left: 13px;top: 7px;color: #725cd3;}
.navbar-main .navbar .navbar-search .form-control{width: 45px;height: 35px;border-radius: 35px;border: 1px solid #725cd3;padding-left: 35px;font-size: 12px;font-weight: 600;transition: all .3s;-webkit-transition: all .3s;}
/* .navbar-main .navbar .navbar-search svg:hover + .form-control{width: 220px;}
.navbar-main .navbar .navbar-search .form-control:hover, .navbar-main .navbar .navbar-search .form-control:focus, .navbar-main .navbar .navbar-search .form-control:active{width: 220px;} */
.navbar-main .navbar .navbar-search svg:hover, .navbar-main .navbar .navbar-search .form-control:hover{cursor: pointer;}
.navbar-main .navbar .mobile-menu-link{position: absolute;top: 16px;right: auto;z-index: 2;stroke: #725cd3;left: 25px;}

.navbar-mega-menu{position: absolute;top: 78px;padding: 20px;background: rgba(236, 240, 241, .9);z-index: 999;border: 1px solid #ccc;border-radius: 10px;left: 20%;backdrop-filter: blur(3px);box-shadow: 0px 10px 25px rgb(0 0 0 / 20%);width: 50%;min-height: 300px;}
.navbar-mega-menu .mega-subitem-main{padding: 0px 5px;margin-bottom: 10px;}
.navbar-mega-menu .mega-subitem-main .col{padding: 15px;background: #fff;border: 1px solid #ddd;font-size: 14px;font-weight: 600;border-radius: 6px;transition: all .3s;-webkit-transition: all .3s;position: relative;}
.navbar-mega-menu .mega-subitem-main .col:hover{background: #fdb01a;cursor: pointer;} /* color: #fff; */
/* .navbar-mega-menu .mega-subitem-main .col:hover img{filter: invert();} */
.navbar-mega-menu .mega-subitem-main .col svg{margin-right: 10px;}
.navbar-mega-menu .mega-subitem-main .col .chev-right svg{margin-right: 0px;margin-left: 10px;}
.navbar-mega-menu .mega-subitem-main .col .chev-right{position: absolute;right: 5px;opacity: 0;transition: all .3s;-webkit-transition: all .3s;}
.navbar-mega-menu .mega-subitem-main .col:hover .chev-right{opacity: 1;}

.main-inner-content .middle-content-header{font-weight: 700;margin-bottom: 40px;color: #2b2350;text-transform: uppercase;font-size: 1.6rem;}
.main-inner-content .breadcrumb{text-transform: uppercase;margin-bottom: 0px;}

.main-footer{width: 100%;font-size: 10px;position: relative;text-align: center;z-index: 1;font-weight: 500;height: 65px;padding-top: 5px;color: #fff;background: linear-gradient(170deg, #0b3064,#041020,#041020);overflow: hidden;}
.main-footer img{display: block;margin: 0 auto;height: 40px;}
.main-inner-content{position: relative;min-height: 100vh;}

.mainsearch-modal .modal-content{background: transparent;border: none;}
.mainsearch-modal .modal-body{padding: 8px;}
.mainsearch-modal .search-icon{position: absolute;margin-top: 12px;margin-left: 12px;color: #725cd3;}
.mainsearch-modal .close-icon{position: absolute;margin-top: 12px;right: 15px;color: #F1654C;}
.mainsearch-modal .close-icon:hover{cursor: pointer;color: #df3314;}
.mainsearch-modal .form-control{padding-left: 45px;height: 50px;border: navajowhite;box-shadow: none;font-weight: 700;color: #725cd3;}
.mainsearch-modal .suggestbox{width: 100%;}
.breadcrumb-item+.breadcrumb-item{font-weight: 600;font-size: 14px;}
.mainsearch-modal .close-modal-link{position: absolute;color: #fff;right: -35px;top: -10px;z-index: 9;}
.mainsearch-modal .close-modal-link:hover{cursor: pointer;opacity: .6;}

.preloading-div{position: fixed;height: 100%;width: 100%;background: #fff;z-index: 999;}

.mobile-sidebar{position: fixed;top: 72px;background: #fff;z-index: 999;height: 100%;padding: 25px 15px;width: 310px;left: -330px;box-shadow: 0px 25px 35px rgb(0 0 0 / 40%);transition: all .5s;-webkit-transition: all .5s;}
.mobile-sidebar.viewsidebar{left: 0px;}
.mobile-sidebar ul{list-style: none;padding-left: 0px;}
.mobile-sidebar ul li{padding: 8px 15px;margin-bottom: 5px;font-size: 18px;text-transform: uppercase;font-weight: 600;border: 1px solid #ccc;border-left: 4px solid #2b2350;color: #0b3064;border-radius: 4px;}
.mobile-sidebar ul li.active{background: #0b3064 !important;color: #fff;border-left-color: #725cd3;}
.mobile-sidebar ul li:hover{background: #efefef;}

.lds-ellipsis {display: inline-block;position: relative;width: 80px;height: 80px;}
.lds-ellipsis div {position: absolute;top: 33px;width: 13px;height: 13px;border-radius: 50%;background: #725cd3;animation-timing-function: cubic-bezier(0, 1, 1, 0);}
.lds-ellipsis div:nth-child(1) {left: 8px;animation: lds-ellipsis1 0.6s infinite;}
.lds-ellipsis div:nth-child(2) {left: 8px;animation: lds-ellipsis2 0.6s infinite;}
.lds-ellipsis div:nth-child(3) {left: 32px;animation: lds-ellipsis2 0.6s infinite;}
.lds-ellipsis div:nth-child(4) {left: 56px;animation: lds-ellipsis3 0.6s infinite;}

@keyframes lds-ellipsis1 {0% {transform: scale(0);}100% {transform: scale(1);}}
@keyframes lds-ellipsis3 {0% {transform: scale(1);}100% {transform: scale(0);}}
@keyframes lds-ellipsis2 {0% {transform: translate(0, 0);}100% {transform: translate(24px, 0);}}
.loading-col-centered{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20%;
    color: blueviolet;
    font-size: 25px;
    font-weight: 600;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0.4;
    }
}

@media (max-width: 1200px) {
    
    
}

@media (max-width: 992px) {
    .navbar-main.subpage-nav .navbar{position: fixed;}
    .navbar-main .navbar-brand{margin-left: 0px;}
    .navbar-main .navbar .navbar-logo{height: 35px;}
    .navbar-main.subpage-nav .navbar-logo{margin-top: 0px;}
    .navbar-main.subpage-nav .navbar-formlist{top: -1rem;right: 1rem;}
    .main-inner-content .middle-content-header{font-size: 1.2rem;}
    .header-content .form-control{width:100% !important;}
    .wrapper-container{overflow-x: hidden;}
}

@media (max-width: 850px) {
    .header-content .form-control{width:100% !important;}
    .main-body-preview-div{position: absolute; right: -40px;}
    .apexcharts-reset-icon{display: none;}
}

@media (max-width: 576px) {
    .navbar-main .navbar .navbar-logo{height: 28px;margin-left: -15px;margin-top: -6px;}
    .mainsearch-modal .form-control{font-size: 12px;margin-top: 3rem;}
    .mainsearch-modal .close-modal-link{right: 20px;top: 10px;}
    .mainsearch-modal .close-icon{right: 30px;}
    ::-webkit-scrollbar {width: 3px;height: 3px;}
    .main-body-preview-div{position: unset; right: unset;}
}

@media (max-width: 350px) {
    .navbar-main .navbar .navbar-logo{height: 24px;}
    .navbar-main .navbar .navbar-search .form-control{width: 35px;height: 28px;padding-left: 22px;}
    .navbar-main .navbar .navbar-search svg{left: 8px;top: 4px;}
    .main-body-preview-div{position: unset; right: unset;}
}