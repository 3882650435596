.suggestbox{box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);position: absolute;background-color: #FFF;padding: 5px;z-index: 10000;border-radius: 10px;width: 90%;margin-top: 4px;max-height: calc(100vh - 130px);overflow-y: scroll;}
.suggestbox .list-group{margin-bottom: 0px;position: relative;z-index: 999;}

.suggestbox .list-group .list-group-item{text-align: left;font-size: 13px;border-radius: 8px; margin-bottom: 4px;border-top: 1px solid #ddd;box-shadow: 0px 2px 5px rgba(0,0,0,0.1);padding: 10px 15px;}
.suggestbox .list-group .list-group-item.active{background: #725cd3 !important;}
.suggestbox .list-group .list-group-item h5{font-size: 15px;margin-bottom: 0px;font-weight: 600;color: #0F3057;width: 88%;}
.suggestbox .list-group .list-group-item small{font-weight: 600;color: #666;font-size: 13px;}
.suggestbox .list-group .list-group-item .badge{position: absolute;right: 10px;background: #1D2247;padding: 2px;font-weight: 500;width: 35px;border-radius: 15px;}
.suggestbox .list-group .list-group-item .badge img{filter: invert();}
.suggestbox .list-group .list-group-item .badge.bar{background: #00BDAA;}
.suggestbox .list-group .list-group-item .badge.pie{background: #FE346E;}
.suggestbox .list-group .list-group-item .badge.line{background: #3E64FF;}
.suggestbox .list-group .list-group-item:hover{background: #EBF5FB; cursor: pointer;}

.close-icon{position: absolute;color: #F1654C;margin-top: 10px;right:32.5%}
.close-icon:hover{cursor: pointer;}

@media (max-width: 768px) {
    .close-icon{right: 25px;}
    .suggestbox{width: 100%;}
    .suggestbox .list-group .list-group-item h5{font-size: 14px;}
}

