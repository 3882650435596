@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
src: url(../poppins/Poppins-Light.ttf);
}
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: url(../poppins/Poppins-Regular.ttf);
}
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
src: url(../poppins/Poppins-Medium.ttf);
}
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
src: url(../poppins/Poppins-SemiBold.ttf);
}
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: url(../poppins/Poppins-Bold.ttf);
}
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 800;
src: url(../poppins/Poppins-ExtraBold.ttf);
}
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 900;
src: url(../poppins/Poppins-Black.ttf);
}