table{background: #FFF;}
#exceltable1 thead tr{border: none !important;}
#exceltable1 thead tr th{background: #7052fb;color: #fff;font-size: 13px;padding: 10px; border:none !important;}
#exceltable1 tbody tr td{padding: 6px 10px;font-size: 13px;font-weight: 600;}
#exceltable1 tbody tr td.valtd{text-align: right;}
.text-center{text-align: center;}

/* .exceltable1-scroll tbody {
    display: block;
    max-height: calc(100vh - 240px);
    overflow: auto;
    
} */

  .exceltable1-scroll {
    table-layout: fixed;
  }

  .exceltable1-scroll thead tr th {
    position:sticky;
    top:-1px;
  }

.mobileview-td{width: 200px;}

.trheadersum th{padding: 3px !important; font-size: 12px !important; font-weight: 500;}

@media (max-width: 576px) {
  .mobileview-td{width: 100px;}
}