
.card-content .subcard-main{background: #fff;box-shadow: 0px 0px 20px rgba(0,0,0,0.15);padding: 25px 15px;margin-bottom: 20px;border-radius: 4px;}
.card-content .subcard-main:hover{opacity: 0.6;cursor: pointer;}
.card-content .subcard-main .thumb-view{height: 130px;width: 100%;background: #eee;border-radius: 8px;margin-bottom: 10px;text-align: center;padding: 18px;padding-top: 30px;color: #999;text-align: left;position: relative;}
.card-content .subcard-main .thumb-view .thumbview-overlay{position: absolute;width: 100%;height: 100%;top: 0px;left: 0px;background: url("../../assets/img/factsheet-overlay.png");background-size: 100% 120%;opacity: .4;}
.card-content .subcard-main h5, .card-content .subcard-main p{margin-bottom: 0px;}
.card-content .subcard-main h5{text-transform: uppercase;font-size: 22px;color: #fff;font-weight: 700;}
.card-content .subcard-main small{color: rgb(236, 236, 236);font-weight: 600;}
.card-content .subcard-main p{font-size: 14px;font-weight: 500;}
.fscontent-main .subcard-main .thumbcancer-icon{position: absolute;width: 50px;height: 50px;right: 5px;background: url("../../assets/img/bodypart_collection.png");bottom: 5px;background-size: 250px 100px;background-position: -200px -50px;opacity: .6;}
.thumbcancer-icon.LTMASR{background-position: -50px 0px !important;}
.thumbcancer-icon.LungASR{background-position: -100px 0px !important;}
.thumbcancer-icon.ThyroidASR, .thumbcancer-icon.PharynxASR, .thumbcancer-icon.PharynxASR,  .thumbcancer-icon.LarynxASR{background-position: -150px -50px !important;}
.thumbcancer-icon.ColonRectumASR{background-position: -150px 0px !important;}
.thumbcancer-icon.OesophagusASR, .thumbcancer-icon.StomachASR{background-position: 0px 0px !important;}
.thumbcancer-icon.CervixASR, .thumbcancer-icon.UterusASR, .thumbcancer-icon.VaginaASR, .thumbcancer-icon.OvaryASR {background-position: -200px 0px !important;}
.thumbcancer-icon.BreastASR{background-position: -100px -50px !important;}
.thumbcancer-icon.BladderASR, .thumbcancer-icon.ProstateASR{background-position: 0px -50px !important;}
.thumbcancer-icon.LymphomaASR{background-position: -50px -50px !important;}

.fscontent-main h2{padding: 10px;font-size: 22px;margin-top: 15px;padding-left: 55px;margin-bottom: 15px;color: #170055;}
.fscontent-main h2 .fsthumb-view{width: 45px;height: 45px;background-color: #CCF6C8;background-image: url("../../assets/img/fs_gender.png");position: absolute;margin-left: -55px;margin-top: -10px;border-radius: 6px;background-size: auto 91%;background-position: -129px 2px;}
.fscontent-main h2.both .fsthumb-view{background-color: #E5CFE5;background-position: 2px 4px;}
.fscontent-main h2.female .fsthumb-view{background-color: #FCD8D4;background-position: -44px 4px;}
.fscontent-main h2.male .fsthumb-view{background-color: #C7FFD8;background-position: -86px 3px;}

.fscontent-main .card-content .subcard-main{padding: 10px;}
.fscontent-main .card-content .subcard-main .thumb-view{min-height: 100px;height:auto;margin-bottom: 0px;}
.fscontent-main .card-content .subcard-main h5{font-size: 20px;font-weight: 500;text-shadow: 0px 0px 5px rgb(0 0 0 / 10%);}

.alertview-main.hidealert{display: none;}

@media (max-width: 1200px) {
    
}

@media (max-width: 768px) {
    .card-content .subcard-main h5{font-size: 18px;}
}
