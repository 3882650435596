

.main-inner-content.header{background: linear-gradient(170deg, #193358, #071c38,#12253f, #051429, #051429);overflow: hidden;}
body.l-mode .main-inner-content.header{background: #fff;}
.header-content{position: relative;padding-bottom: 2rem;}
.header-content .header-logo{height: 4rem;margin-bottom: 0rem;margin-top: 0rem;}
.header-content .header-overlay-bg{position: absolute;width: 100%;height: 39rem;background: transparent;opacity: .5;}/*background: url("../../assets/img/home/home_overline.png");background-size: 103% 100%;border-radius: 5rem;margin-top: -16rem;transform: rotate(5deg);box-shadow: 20px 10px 25px rgb(6 14 73 / 20%);*/
body.l-mode .header-content .header-overlay-bg{background: url("../../assets/img/home/home_overline.png");background-size: 103% 100%;opacity: 1;}
.header-content .container{position: relative;padding-top: 2rem;z-index: 2;}
.header-content h2{color: #fff;font-weight: 600;font-size: 1.8rem;margin-top: 1rem;}
body.l-mode .header-content h2{color: #2b2350;}
.header-content h2 small{font-size: 1.2rem;color: #afa0f3;text-transform: none;display: block;margin-top: 0px;}
body.l-mode .header-content h2 small{color: #afa0f3;}
.header-content .form-control{margin-top: 1.5rem;padding: 10px 20px;font-size: 15px;font-weight: 700;padding-left: 45px;box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);background: rgba(255, 255, 255, 1);width:70%;}
.header-content .search-icon{position: absolute;color: #42729B;margin-top: 10px;margin-left: 12px;}
.header-content .col-md-5 svg path{fill: #2b2350;}
.header-content .col-md-5 svg circle{filter: drop-shadow( 0px 0px 10px #b2f9fd);-webkit-filter: drop-shadow( 0px 0px 10px #b2f9fd);}
.header-content .animated{animation-fill-mode: none;}

.landing-middle-content{overflow: hidden;position: relative;padding: 2rem 0 5rem 0;} /* background: linear-gradient(140deg, #725cd3, #725cd3, #5c68d3); */
body.l-mode .landing-middle-content h2{color: #fff !important;}
.landing-middle-content .middle-overlay-bg{position: absolute;width: 120%;border-radius: 5rem;background: linear-gradient(130deg, #e2f0fa, #fff);height: 20rem;margin-right: -16rem;bottom: -15rem;transform: rotate(10deg);}
.landing-middle-content .container{position: relative;}
.header-content .sub-content .sub-container{box-shadow: 0px 0px 25px rgb(178 249 253 / 30%);padding: 15px;border-radius: 0.5rem;text-align: center;padding: 5px;height: 9rem;border: 2px solid #afa0f3;margin-bottom: 10px;padding-top: 20px;}
body.l-mode .header-content .sub-content .sub-container{border-color: #fff;background: rgba(255,255,255,1);box-shadow: 0px 5px 15px rgba(70, 105, 220, 0.5);}
.header-content .sub-content .sub-container h3{margin-bottom: 0px;margin-top: 5px;font-size: 18px;font-weight: 600;text-transform: uppercase;letter-spacing: -1px;line-height: 18px;;color: #26b2e6;}
body.l-mode .header-content .sub-content .sub-container h3{color: #2b2350;}
.header-content .sub-content .sub-container p{ font-size: 12px;font-weight: 600;margin-top: 8px;color: #b2f9fd;margin-bottom: 3px;}
.header-content .sub-content .sub-container:hover{cursor: pointer;opacity: .8;}

.map-popup-main{position: absolute;top:0px}
.lds-content{position: absolute;background: #fff;border-radius: 8px;padding: 5px 8px;line-height: 18px;margin-left: 32px;font-size: 12px;margin-top: 20px;}
.lds-content::after{content: '';position: absolute;left: 29px;top: 42px;width: 0;height: 0;border-left: 20px solid transparent;border-right: 20px solid transparent;border-top: 15px solid #fff;clear: both;}
.lds-content label{font-weight: 600;}
.lds-content small{font-size: 10px;font-weight: 500;}
.lds-ripple{display: inline-block;position: relative;width: 80px;height: 80px;margin-left: 49px;margin-top: 39px;z-index: 2;}
.lds-ripple div {position: absolute;border: 4px solid #b2f9fd;opacity: 1;border-radius: 50%;animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;}
.lds-ripple div:nth-child(2) {animation-delay: -0.5s;}

/*body*/
.body-preview{position: relative;width: 340px;height: 460px;margin-top: 6rem;transition: all .3s;-webkit-transition: all .3s;z-index: 9;}
.body-preview.active{transform: scale(.3);margin-top: -100px;}
.body-preview .main-body{margin-top: -4rem;width: 360px;height: 530px;position: absolute;transition: all .3s;-webkit-transition: all .3s;}
body.l-mode .body-preview .main-body{filter: brightness(0.5);}
.body-preview .sub-body-part{width: 60px;position: absolute;left: 44%;top: 8%;transition: all .2s;-webkit-transition: all .2s;}
.body-preview .sub-body-part img{width: 100%;height: auto;opacity: 0.5;filter: grayscale(100%);}
.body-preview .sub-body-part .glow-view{position: absolute;height: 5px;width:5px;background: #a2fdfe;border-radius: 10px;box-shadow: 0px 0px 5px #a2fdfe;margin-top: -50%;margin-left: 40%;}

/* .roadmap-content{background: linear-gradient(130deg, #122138, #051429, #051429);} */

.glow-view{
    position:absolute;
    background-color:#B1FFFD;
    -webkit-animation: grow .4s 1 linear;
    animation: grow .4s 1 linear;
    
  }
  .glow-view:before{
    position:absolute;
    content:"";
    height:5px;
    width:5px;
    left:0;
    top:0;
    background-color:transparent;
    border-radius:10px;
    box-shadow:0px 0px 2px 2px #B1FFFD;
    -webkit-animation:active 1s infinite linear;
    animation:active 1s infinite linear;
  }
  
  .glow-view:after{
    position:absolute;
    content:"";
    height:5px;
    width:5px;
    left:0;
    top:0;
    background-color:transparent;
    border-radius:10px;
    box-shadow:0px 0px 2px 2px #B1FFFD;
    -webkit-animation:active 2s infinite linear;
    animation:active 2s infinite linear;
  }
  
  @-webkit-keyframes grow {
    0% {
      -webkit-transform: scale(.1);
    }
    
    100% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes grow {
    0% {
      transform: scale(.1);
    }
    
    100% {
      transform: scale(1);
    }
  }
  
  @-webkit-keyframes active{
    0%{
      -webkit-transform:scale(.1);
      opacity:1;
    }
    70%{
      -webkit-transform:scale(2.5);
      opacity:0;
    }
    100%{
      opacity:0;
    }
  }
  
  @keyframes active{
    0%{
      transform:scale(.1);
      opacity:1;
    }
    70%{
      transform:scale(2.5);
      opacity:0;
    }
    100%{
      opacity:0;
    }
  }

.body-preview .sub-body-part:hover .glow-view::before, .body-preview .sub-body-part:hover .glow-view::after{box-shadow:0px 0px 2px 2px #F54748;}

body.l-mode .body-preview .sub-body-part{filter: invert();}
.body-preview .sub-body-part:hover, .body-preview .sub-body-part.active{cursor: pointer;}
.body-preview .sub-body-part:hover img, .body-preview .sub-body-part.active img{opacity: 1 !important;filter: grayscale(0%);}
.body-preview .sub-body-part:hover .glow-view,.body-preview .sub-body-part:hover .glow-view, .body-preview .sub-body-part.active .glow-view{background: #F54748;}
.body-preview .sub-body-part.breasts{top: 11%;width: 74px;left: 41.5%;}
.body-preview .sub-body-part.face{top: -3%;width: 45px;height: 24px;left: 45.5%;}
.body-preview .sub-body-part.thyroid{top: 1.5%;width: 15px;left: 50.1%;}
.body-preview .sub-body-part.lymphoma{top: 5%;width: 47px;left: 44.5%;}
.body-preview .sub-body-part.stomach{top: 17.5%;width: 40px;left: 50%;}
.body-preview .sub-body-part.liver{top: 16.5%;width: 45px;left: 44%;}
.body-preview .sub-body-part.bladder{top: 37.5%;width: 38px;left: 47%;}
.body-preview .sub-body-part.uterus{top: 31.5%;width: 45px;left: 46%;}
.body-preview .sub-body-part.colon{top: 27.5%;width: 50px;left: 45.5%;}
.body-preview .sub-body-part.kidneyleft{top: 31.5%;width: 20px;left: 56%;}
.body-preview .sub-body-part.kidneyright{top: 31.5%;width: 20px;left: 43%;}
.description-view {position: absolute;top: 12rem;width: 380px;padding-bottom: 5px;margin-left: -1.1rem;background: #122138;border-radius: 4px;opacity: 0;box-shadow: 0px 0px 30px rgba(162, 253, 254, .3);transition: all .8s;-webkit-transition: all .8s;} /* min-height: 20rem; */
body.l-mode .description-view {background: #fff;}
.description-view.active{opacity: 1;}
.description-view::before{content:"";position: absolute;width: 0; height: 0;border-left: 10px solid transparent;border-right: 10px solid transparent;border-bottom: 6px solid rgba(70, 178, 220, 0.699);margin-top: -5px;margin-left: 47.5%;}
body.l-mode .description-view::before{border-bottom-color: rgba(70, 105, 220, 0.699);}
.description-view .desc-header{background: #7052fb;padding: 20px;color: #fff;position: relative;border-top-left-radius: 4px;border-top-right-radius: 4px;border-top: 2px solid #7052fb;}
body.l-mode .description-view .desc-header{background: #0b86b3;border-top-color: #0b86b3;}
body.l-mode .description-view .desc-header{background: #725cd3}
.description-view .desc-content h4{margin: 0px;text-transform: uppercase;font-size: 16px;text-align: left;color: #a2fdfe;font-weight: 500;padding: 0px 10px;}
body.l-mode .description-view .desc-content h4{color: #2b2350;}
.description-view .desc-header .close-btn{position: absolute;right: 10px;top: 8px;z-index: 2;}
.description-view .desc-header .close-btn:hover{opacity: .6;cursor: pointer;}
.description-view .desc-content .preview-content{width: 100%;text-align: center;background: #7052fb;padding: 10px;height: 10px;z-index: 1;position: relative;margin-bottom: 2rem;}
body.l-mode .description-view .desc-content .preview-content{background: #0b86b3;}
body.l-mode .description-view .desc-content .preview-content{background: #725cd3;}
.description-view .desc-content .preview-content .img-thumb{width: 70px;height: 70px;display: block;margin: 0 auto;background: #122138;margin-top: -40px;border-radius: 41px;box-shadow: 0px 1px 5px rgb(255 255 255 / 50%);}
body.l-mode .description-view .desc-content .preview-content .img-thumb{background: #fff;box-shadow: 0px 1px 5px rgb(0 0 0 / 20%);}
.description-view .desc-content .preview-content img{margin-top: 10px;height: 65%;}
body.l-mode .description-view .desc-content .preview-content img{filter: invert();}
.description-view .desc-content p{margin-top: 4px;font-size: 12px;font-weight: 400;color:#fff;padding: 5px 10px;margin-bottom: 5px;overflow: hidden;transition: all .3s;-webkit-transition: all .3s;}
.description-view .desc-content p.overflow-view{height: 60px;}
body.l-mode .description-view .desc-content p{color: #1f5aac;}
.description-view .desc-content .table{margin-bottom: 10px;}
.description-view .desc-content .table tr{border-color: #0e192b;}
body.l-mode .description-view .desc-content .table tr{border-color: #fff;}
.description-view .desc-content .table th{font-size: 10px;background: #1a3052;color: #eee;border-color: #122138;}
body.l-mode .description-view .desc-content .table th{background: #e5e8f3;color: #1f5aac;border-color: #fff;}
.description-view .desc-content .table td{font-size: 12px;padding: 5px 10px;background: #0e192b;color: #eee;border-color: #122138;}
body.l-mode .description-view .desc-content .table td{background: #e2f0fa;color: #1f5aac;border-color: #fff;}
.description-view .btn{font-weight: 600;box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);margin-bottom: 10px;background: #7052fb;border-color: #7052fb;position: absolute;right: 10px;margin-top: -4px;font-size: 12px;}

.description-view .single-site-view{padding: 15px 5px;border: 1px solid #42729B;border-radius: 4px;margin-bottom: 5px;position: relative;}
.description-view .single-site-view:last-child{margin-bottom: 0px;}
.description-view .single-site-view.oneitem{border: none;}
.description-view .single-site-view.oneitem .btn{position: relative;}
.description-view .single-site-view.oneitem h4{text-align: center;}
.description-view .single-site-view.oneitem p{margin-bottom: 20px;height: auto;}
.description-view .single-site-view p.viewall{height: auto;cursor: pointer;}
.description-view .single-site-view p.viewall + .viewmore-link{display: none;}
.description-view .viewmore-link{position: absolute;width: 100%;text-align: center;font-weight: 600;font-size: 12px;color: #6994cc;bottom: 0px;padding: 2px;padding-top: 8px;background: linear-gradient(to bottom, transparent, rgba(18, 33, 56, .8), rgba(18, 33, 56, 1));transition: all .3s;-webkit-transition: all .3s;}
.description-view .viewmore-link:hover{cursor: pointer;color: #26b2e6;}

.button-tooltip > .tooltip-inner {background-color: #1f5aac;color: #fff;border: 1px solid #2b2350;font-weight: 600; font-size: 13px;}

.bodymap-toggle-list {width: 360px;margin-top: -2.4rem;position: relative;z-index: 11;}
.bodymap-toggle-list .btn{width: 90px;height: 30px;font-size: 12px;text-transform: uppercase;background: linear-gradient(45deg, #7052fb, #2D568C);box-shadow: none !important;}
body.l-mode .bodymap-toggle-list .btn{background: linear-gradient(45deg, #a5a5c7, #6994cc);}
.bodymap-toggle-list .btn.active{background: linear-gradient(45deg, #7052fb, #0b86b3) !important;border-color: #0b86b3;}
.body-overlay-main{ height:31rem; position:relative; }

@keyframes lds-ripple {
    0% {top: 36px;left: 36px;width: 0;height: 0;opacity: 1;}
    100% {top: 0px;left: 0px;width: 72px;height: 72px;opacity: 0;}
}
/*roadmap view*/
.timeline-carousel {padding: 86px 6.9444% 90px 6.9444%;position: relative;overflow: hidden;}
.timeline-carousel:after, .timeline-carousel:before {content: "";position: absolute;display: block;top: 0;height: 100%;width: 6.9444%;background-color: #1d1d1e;z-index: 3;width: 6.9444%;}
.timeline-carousel:after {left: 0;}
.timeline-carousel:before {right: 0;opacity: 0;}
.timeline-carousel .slick-list {overflow: visible;}
.timeline-carousel .slick-dots {bottom: -73px;}
.timeline-carousel h1 {color: white;font-size: 46px;line-height: 50pd;margin-bottom: 40px;font-weight: 900;}
.timeline-carousel__image {padding-right: 30px;}
.timeline-carousel__item {cursor: pointer;}
.timeline-carousel__item .media-wrapper {opacity: 0.4;padding-bottom: 71.4%;-webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);-o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);}
.timeline-carousel__item:last-child .timeline-carousel__item-inner:after {width: calc(100% - 30px);}
.timeline-carousel__item-inner {position: relative;padding-top: 45px;}
.timeline-carousel__item-inner:after {position: absolute;width: 100%;top: 45px;left: 0;content: "";border-bottom: 2px dashed #a2fdfe;}
.timeline-carousel__item-inner .year {font-size: 28px;line-height: 36px;color: white;display: table;letter-spacing: -1px;padding-right: 10px;z-index: 1;position: relative;margin: -15px 0 20px;font-weight: 600;margin-top: -55px;margin-left: 20px;}
.timeline-carousel__item-inner .year:after {content: "";position: absolute;display: block;left: -20px;top: 0;height: 100%;width: 10px;background-color: #a2fdfe;z-index: 3;}
body.l-mode .timeline-carousel__item-inner .year:after{background: rgb(12, 190, 252);}
.timeline-carousel__item-inner .month {font-size: 12px;text-transform: uppercase;color: #b38c52;display: block;margin-bottom: 10px;font-weight: 900;}
.timeline-carousel__item-inner p {font-size: 14px;line-height: 18px;color: white;width: 90%;margin-bottom: 15px;padding-left: 15px;font-weight: 500;}
body.l-mode .timeline-carousel__item-inner p{color: #e0e0fc;}
.timeline-carousel__item-inner p::before{content: "";width: 8px;height: 8px;background: #a2fdfe;border-radius: 15px;position: absolute;margin-left: -14px;margin-top: 5px;}
.timeline-carousel__item-inner p:hover{cursor: pointer;color: #0b86b3;}
body.l-mode .timeline-carousel__item-inner p:hover{color: #2b2350 !important;}
.timeline-carousel__item-inner .read-more {font-size: 12px;color: #b38c52;display: table;margin-bottom: 10px;font-weight: 900;text-decoration: none;position: relative;}
.timeline-carousel__item-inner .read-more:after {content: "";position: absolute;left: 0;bottom: -1px;width: 0;border-bottom: 2px solid #b38c52;-webkit-transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);-o-transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);}
.timeline-carousel__item-inner .read-more:hover:after {width: 100%;}
.timeline-carousel__item-inner .pointer {height: 25px;position: relative;z-index: 1;margin: -4px 0 16px;margin-bottom: 5px;}
.timeline-carousel__item-inner .pointer:after, .timeline-carousel__item-inner .pointer:before {position: absolute;content: "";}
.timeline-carousel__item-inner .pointer:after {width: 9px;height: 9px;border-radius: 100%;top: 0;left: 0;background-color: #a2fdfe;box-shadow: 0px 0px 15px #a2fdfe;}
body.l-mode .timeline-carousel__item-inner .pointer:after{background: #a2fdfe;}
.timeline-carousel__item-inner .pointer:before {width: 1px;height: 100%;top: 0;left: 4px;background-color: #a2fdfe;}
.timeline-carousel .slick-active .media-wrapper {opacity: 1 !important;}

.slick-slider{margin-top: 30px;}
.slick-slider .slick-list{padding-top: 25px;}
.slider-options{position: absolute;right: 0px;top:5px;}
.slider-options .btn{box-shadow: none;background: transparent;border-color: #7052fb;}
.slider-options .btn:first-child{margin-right: 5px;}
.slick-initialized .slick-slide{padding-left: 14px;}

#particlesview{position: absolute;}
#particlesview canvas{background-color: transparent;width:100%;height: 39rem !important;}

.devlogo-footer{position: absolute;right: 15px;margin-top: -40px !important;height: 30px !important;}
.roadmap-content h2{margin-top:30px;padding-left:10px;border-left:5px solid #0CBEFC;color:#fff;}

@media (max-width: 1200px) {
    .header-content .header-overlay-bg, #particlesview canvas{height: 41rem !important;}
    .body-preview{margin-top: 6rem;}
}

@media (max-width: 768px) {
    .header-content .header-overlay-bg, #particlesview canvas{height: 42rem !important;}
    .landing-middle-content{padding: 6rem 0 5rem 0;}
    .body-preview{margin-top: 6rem;}
    .header-content .header-logo{height: 3rem;}
    .header-content .form-control{width: 100%;}
    .home-link-content .sub-content{padding: 0px 15px !important;padding-right: 25px !important;}
    .header-content .sub-content .sub-container img{width: 26% !important;}
    .slider-options{right: 10px;top: 0px;}
    .main-footer{font-size: 10px;}
    .description-view{top: 11rem;margin-left: 1.2rem;width: 300px;}
    .button-tooltip{display: none;}
    .header-content .sub-content .sub-container{height: 7rem;padding-bottom: 15px;}
    .mainSearchCol{padding-right: 13px !important;}
    .header-content .sub-content .sub-container{height: auto;padding-bottom: 15px;}
    
}

@media (max-width: 767px) {
  .main-body-preview-div {right:14%}
}

@media (max-width: 576px) {
    .header-content h2{font-size: 1.6rem;}
    .header-content h2 small{font-size: 0.8rem;margin-top: 10px;}
    .header-content .search-icon{margin-top: 6px;}
    .header-content .form-control{font-size: 10px !important;}
    .header-content .header-overlay-bg, #particlesview canvas{height: 102rem!important;}
    .landing-middle-content{padding: 10rem 0 5rem 0;}
    .header-content .sub-content .sub-container{height: auto;padding-bottom: 15px;}
    .devlogo-footer{height: 20px !important;margin-top: -40px !important;}
    .body-overlay-main{height: 37rem;}
    .body-preview{margin: 0 auto;margin-top: 10rem;}
    .bodymap-toggle-list{width: 100%;}
    .description-view{top: 11rem;margin-left: 1.2rem;width: 94%;margin: 0 auto;}
    .roadmap-content h2{font-size: 18px;}
}

@media (max-width: 320px) {
  .body-preview{margin-left: -1.8rem;}
  .body-preview.active{margin-left: -1.1rem;}
}